html {
    background-color: #fecfe4;
}
body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #434343;
}
