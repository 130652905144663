.card {
    background-color: white;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;

    box-shadow: rgba(78, 50, 78, 0.156) 0px 7px 29px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 5px;
}

.amrap {
    background-color: #cb92abc3;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 90%;
    }
}

@media only screen and (min-width: 600px) {
    .card {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) {
    .card {
        width: 75%;
    }
}

@media only screen and (min-width: 1200px) {
    .card {
        width: 65%;
    }
}

@media only screen and (min-width: 1500px) {
    .card {
        width: 50%;
    }
}
